exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../../../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-contentful-blog-slug-js": () => import("./../../../src/pages/news/{ContentfulBlog.slug}.js" /* webpackChunkName: "component---src-pages-news-contentful-blog-slug-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-programma-fidelity-js": () => import("./../../../src/pages/programma-fidelity.js" /* webpackChunkName: "component---src-pages-programma-fidelity-js" */),
  "component---src-pages-servizi-assicurazioni-js": () => import("./../../../src/pages/servizi/assicurazioni.js" /* webpackChunkName: "component---src-pages-servizi-assicurazioni-js" */),
  "component---src-pages-servizi-biglietti-js": () => import("./../../../src/pages/servizi/biglietti.js" /* webpackChunkName: "component---src-pages-servizi-biglietti-js" */),
  "component---src-pages-servizi-conti-correnti-js": () => import("./../../../src/pages/servizi/conti-correnti.js" /* webpackChunkName: "component---src-pages-servizi-conti-correnti-js" */),
  "component---src-pages-servizi-crypto-trading-js": () => import("./../../../src/pages/servizi/crypto-trading.js" /* webpackChunkName: "component---src-pages-servizi-crypto-trading-js" */),
  "component---src-pages-servizi-e-commerce-js": () => import("./../../../src/pages/servizi/e-commerce.js" /* webpackChunkName: "component---src-pages-servizi-e-commerce-js" */),
  "component---src-pages-servizi-luce-gas-js": () => import("./../../../src/pages/servizi/luce-gas.js" /* webpackChunkName: "component---src-pages-servizi-luce-gas-js" */),
  "component---src-pages-servizi-mysql-programs-name-mysql-programs-mysql-id-js": () => import("./../../../src/pages/servizi/{MysqlPrograms.name}-{MysqlPrograms.mysqlId}.js" /* webpackChunkName: "component---src-pages-servizi-mysql-programs-name-mysql-programs-mysql-id-js" */),
  "component---src-pages-servizi-noleggio-auto-js": () => import("./../../../src/pages/servizi/noleggio-auto.js" /* webpackChunkName: "component---src-pages-servizi-noleggio-auto-js" */),
  "component---src-pages-servizi-telefonia-js": () => import("./../../../src/pages/servizi/telefonia.js" /* webpackChunkName: "component---src-pages-servizi-telefonia-js" */),
  "component---src-pages-termini-e-condizioni-js": () => import("./../../../src/pages/termini-e-condizioni.js" /* webpackChunkName: "component---src-pages-termini-e-condizioni-js" */)
}

