import React, { useState, useEffect, useContext } from "react"
import createAuth0Client from "@auth0/auth0-spa-js"

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)

let _initOptions = {
  domain: process.env.GATSBY_AUTH0_DOMAIN,
  client_id: process.env.GATSBY_AUTH0_CLIENT_ID,
  redirect_uri: process.env.GATSBY_AUTH0_CALLBACK,
  cacheLocation: "localstorage",
  audience: process.env.GATSBY_AUTH0_AUDIENCE,
  scope: process.env.GATSBY_AUTH0_SCOPE,
  useCookiesForTransactions: true
}
let _client
const getAuth0Client = () => {
  return new Promise(async (resolve, reject) => {
    let client
    if (!client) {
      try {
        client = await createAuth0Client(_initOptions)
        resolve(client)
      } catch (e) {
        reject(new Error("getAuth0Client Error", e))
      }
    }
  })
}

export const getApiToken = async (...p) => {
  if (!_client) {
    _client = await getAuth0Client()
  }
  return await _client.getTokenSilently(...p)
}

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [error, setError] = useState(false)
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  const [gaClientId, setGaClientId] =  useState(null)


  useEffect(() => {
    const initAuth0 = async () => {
      _initOptions = initOptions
      const client = await getAuth0Client(initOptions)
      setAuth0(client)
      process.env.NODE_ENV === 'production' && typeof window !== "undefined" && window.gtag("get", process.env.GA_TRACKING_ID, "client_id", client_id => setGaClientId(client_id))
      const isAuthenticated = await client.isAuthenticated()
      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await client.getUser()
        setUser(user)
        setLoading(false)
        if(window.location.search.includes("code=")){
          window.history.replaceState({}, window.document.title, window.location.protocol + '//' + window.location.host + window.location.pathname);
        }
        return;
      }
      try{
        if (window.location.search.includes("code=")) {
          const { appState } = await client.handleRedirectCallback()
          onRedirectCallback(appState)
        }
      }catch(err){
        setError(err);
      }
      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        error,
        gaClientId,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
